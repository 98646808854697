<template>
	<div>
		<h2>{{ languageStrings.createAccount }}</h2>
		<div id="registration-pages">
			<div id="page-indicators">
				<div
					v-for="(item, index) in newAccountComponents"
					:key="index"
					:id="`page${index}`"
					:class="index <= currentComponent ? 'active' : ''"
					:title="index <= currentComponent ? `${languageStrings.clickToGoToStep}  ${index + 1}` : ''"
					@click="currentComponent = index < currentComponent ? index : currentComponent"
				>
					{{ index + 1 }}
				</div>
			</div>
			<div class="registration-input-container encore-background">
				<transition name="fade">
					<component
						v-bind:is="newAccountComponents[currentComponent]"
						:systemSettings="systemSettings"
						:serverRules="serverRules"
						:isMobile="isMobile"
						:languageStrings="languageStrings"
						:languageErrorStrings="languageErrorStrings"
						:countryList="countryList"
						:playerState="playerState"
						:newAccountProperties="newAccountProperties"
					/>
				</transition>
				<!-- <div class="checkbox">
					<input type="checkbox" id="ageCheck" name="Age Check" :title="languageStrings.ageCheckText" v-model="ageCheck" />
					<label for="ageCheck" :title="languageStrings.ageCheckText">{{ languageStrings.ageCheckText }}</label>
				</div> -->
				<Captcha
					v-if="serverRules.captchaType !== 'None' && serverRules.captchaType !== ''"
					:serverRules="serverRules"
					:isMobile="isMobile"
					:languageStrings="languageStrings"
				/>
				<div class="pad-bottom">
					<p class="lines">Or</p>
					<router-link to="/" :title="languageStrings.login" class="btn login">{{ languageStrings.login }}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import router from "@/router";
import { onBeforeUnmount } from "vue";
import { parsePhoneNumber } from "awesome-phonenumber";
import sharedScripts from "@/dependencies/sharedScripts";
import TermsAndConditions from "@/components/TermsAndConditions";
import Captcha from "@/components/Captcha";
import UserNameForm from "@/components/UserNameForm";
import EmailPhoneForm from "@/components/EmailPhoneForm";
import PasswordForm from "@/components/PasswordForm";
import PhoneNumberForm from "@/components/PhoneNumberForm";
import PhoneVerification from "@/components/PhoneVerification";
import FirstAndLastName from "@/components/FirstAndLastName";
import BirthDate from "@/components/BirthDate";
import Nationality from "@/components/Nationality";
import ZipcodeForm from "@/components/ZipcodeForm";

export default {
	name: "ExtendedRegisterView",
	components: {
		Captcha,
		TermsAndConditions,
		UserNameForm,
		EmailPhoneForm,
		PasswordForm,
		PhoneNumberForm,
		PhoneVerification,
		FirstAndLastName,
		BirthDate,
		Nationality,
		ZipcodeForm,
	},
	props: {
		playerState: Object,
		capsLockOn: Boolean,
		serverRules: Object,
		isMobile: Boolean,
		systemSettings: Object,
		countryList: Array,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			newAccountProperties: {},
			newAccountComponents: [
				UserNameForm,
				EmailPhoneForm,
				FirstAndLastName,
				BirthDate,
				Nationality,
				ZipcodeForm,
				PasswordForm,
				PhoneVerification,
			],
			currentComponent: 0,
			captchaResponse: null,
			selectedCountry: null,
			ageCheck: null,
			hasErrors: false,
		};
	},
	watch: {
		// newAccountProperties: {
		// 	handler() {
		// 		if (this.newAccountProperties.componentName === "PhoneNumberForm") {
		// 			this.registerNewUser();
		// 		}
		// 	},
		// 	deep: true,
		// },
	},
	created() {
		this.eventBus.on("advanceComponent", () => {
			if (this.newAccountProperties.componentName === "PasswordForm") {
				this.registerNewUser();
				return;
			}
			if (!this.hasErrors) this.currentComponent++;
		});
		this.eventBus.on("addNewAccountProperty", (payload) => {
			let newAccount = this.newAccountProperties;
			newAccount[payload.propertyName] = payload.propertyValue;
			this.newAccountProperties = newAccount;
			this.newAccountProperties.componentName = payload.componentName;
		});
		this.eventBus.on("submitNewAccount", () => {
			console.log(this.newAccountProperties);
			// this.registerNewUser();
		});
		this.eventBus.on("captchaSuccess", (payload) => {
			this.captchaResponse = payload;
		});
		this.eventBus.on("countrySelected", (payload) => {
			if (payload.countryDropdownType === "PhoneNumber") {
				this.newAccountProperties.playersCountry = payload;
				this.selectedCountry = payload;
			}
		});
		onBeforeUnmount(() => {
			this.eventBus.off("advanceComponent");
			this.eventBus.off("addNewAccountProperty");
			this.eventBus.off("submitNewAccount");
			this.eventBus.off("captchaSuccess");
			this.eventBus.off("countrySelected");
		});
	},
	mounted() {
		if (this.serverRules.captchaType !== "None" && this.serverRules.captchaType !== "") this.eventBus.emit("initializeCaptcha");
	},
	methods: {
		async getLatestTosDocument() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.loadingTermsAndConditions;

			try {
				this.tosList = await this.listDocuments(this, "tos", 10);
				if (!this.tosList?.length > 0) {
					this.serverBusy = false;
					this.busyText = "";
					return;
				}
				this.latestVersion = this.compareDocumentVersions(this.tosList);

				let documentUrl = this.latestVersion.url.indexOf("http") === 0 ? this.latestVersion.url : `./${this.latestVersion.url}`;

				let content = await fetch(documentUrl);
				let dataJson = await content.text();
				this.tosContent = this.removeScriptTag(dataJson);

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
			}
		},
		async registerNewUser() {
			// super long upper limit to phone number length when systemSettings.allowPhoneExtensionDigits == true.
			// No one would ever need a number that large in the real world but this is for Dev testing.
			let phoneNumberLimit = this.systemSettings.allowPhoneExtensionDigits ? 200 : this.phoneNumberLength;

			this.validatePhoneExtensionDigits();

			this.hasErrors = false;

			if (!this.selectedCountry.countryPrefix) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.selectCountry;
				this.eventBus.emit("updateStatus", this.status);
				this.hasErrors = true;
			}

			if (!this.newAccountProperties.phoneNumber) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.phoneInvalid;
				this.eventBus.emit("updateStatus", this.status);
				this.hasErrors = true;
			}

			if (!this.newAccountProperties.displayName) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.enterDisplayName;
				this.eventBus.emit("updateStatus", this.status);
				this.hasErrors = true;
			}

			if (!this.newAccountProperties.password) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.providePassword;
				this.eventBus.emit("updateStatus", this.status);
				this.hasErrors = true;
			}

			if (!this.newAccountProperties.emailAddress) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.emailRequired;
				this.eventBus.emit("updateStatus", this.status);
				this.hasErrors = true;
			}

			if (this.newAccountProperties?.password?.length < this.serverRules.passwordRules?.minimumLength) {
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.passwordMustHaveAtLeast} ${this.serverRules.passwordRules?.minimumLength} ${this.languageErrorStrings.characters}`;
				this.eventBus.emit("updateStatus", this.status);
				this.hasErrors = true;
			}

			if (this.serverRules.captchaType !== "None" && this.serverRules.captchaType !== "" && !this.captchaResponse) {
				this.captchaMessage = this.languageErrorStrings.challengeNotSolved;
				this.hasErrors = true;
			}

			if (this.hasErrors === true) return;

			this.captchaMessage = "";

			let body = {
				phoneNumber: this.newAccountProperties.phoneNumber.toString(),
				phoneCountryCode: this.newAccountProperties.playersCountry.countryPrefix,
				displayName: this.newAccountProperties.displayName,
				password: this.newAccountProperties.password,
				requestVerificationCode: true,
				requiredValidations: "Minimal, BasicTransactions",
				personalInfo: {
					birthDate: this.newAccountProperties.birthDate,
					nationality: this.newAccountProperties.nationality,
					emailAddress: this.newAccountProperties.emailAddress,
					nameFirst: this.newAccountProperties.firstName,
					nameLast: this.newAccountProperties.lastName,
					addressCountry: this.newAccountProperties.addressCountry,
					addressZipCode: this.newAccountProperties.addressZipCode,
				},
			};

			if (this.serverRules.captchaType !== "None" && this.serverRules.captchaType !== "" && this.captchaResponse)
				body.captchaResponse = this.captchaResponse;

			let requestUrl = new URL("/api/v1/user/register", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				if (dataJson?.status === "PhoneNumberAlreadyInUse") {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.phoneNumberAlreadyInUse;
					this.eventBus.emit("updateStatus", this.status);
					router.push("/");
					return false;
				}

				if (dataJson?.status === "FailedPersonalInfoValidation") {
					dataJson.validationErrors.forEach((err) => {
						this.status.ok = false;
						this.status.message = `${err.field}: ${err.reason}`;
						this.eventBus.emit("updateStatus", this.status);
					});
					return false;
				}

				if (fetchStatus.ok) this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (this.status.ok) {
					this.smsSent = true;
					let newPlayer = {
						phoneNumber: this.newAccountProperties.phoneNumber,
						displayName: this.newAccountProperties.displayName,
					};
					this.eventBus.emit("updatePlayerState", newPlayer);
					this.status.ok = true;
					this.status.message = this.languageErrorStrings.registrationSuccess;
					this.currentComponent++;
				}

				this.eventBus.emit("updateStatus", this.status);
				return;
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
			}
		},
		validatePhoneExtensionDigits() {
			// This is mostly for testing.
			// QA often has accounts set up with too many digits to validate.
			// We truncate to a possibly valid number before validating.
			// If systemSettings.allowPhoneExtensionDigits === true then we will send the unaltered number to the server.

			// type phoneNumberPossibility =
			// | 'is-possible'
			// | 'invalid-country-code'
			// | 'too-long'
			// | 'too-short'
			// | 'unknown'

			this.possiblePhoneNumber = `+${this.selectedCountry.countryPrefix}${this.phoneNumber}`;
			let parsedPhoneNumber = parsePhoneNumber(this.possiblePhoneNumber);
			let phoneNumberPossibility = parsedPhoneNumber.possibility;

			if (phoneNumberPossibility !== "too-long" && phoneNumberPossibility !== "unknown" && phoneNumberPossibility !== "is-possible") {
				this.isPossiblePhone = false;
				return;
			}

			if (phoneNumberPossibility === "too-long" && !this.systemSettings.allowPhoneExtensionDigits) {
				this.isPossiblePhone = false;
				return;
			}

			for (let i = 0; i < this.possiblePhoneNumber.length && phoneNumberPossibility !== "is-possible"; i++) {
				parsedPhoneNumber = parsePhoneNumber(this.possiblePhoneNumber);
				phoneNumberPossibility = parsedPhoneNumber.possibility;
				if (phoneNumberPossibility !== "is-possible")
					this.possiblePhoneNumber = this.possiblePhoneNumber.substring(0, this.possiblePhoneNumber.length - 1);
			}

			this.shortenedNumber = this.possiblePhoneNumber;

			this.possiblePhoneNumber = this.isPossiblePhone = phoneNumberPossibility === "is-possible" ? true : false;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div#registration-pages {
	display: flex;
	flex-direction: column;
	text-align: center;
	height: calc(100% - 160px);
	justify-content: space-around;
	overflow: hidden auto;
}

#page-indicators {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	font-weight: bold;
	font-size: 1.5em;
	user-select: none;
}

#page-indicators > div {
	border-radius: 100%;
	flex: 0 1 auto;
	text-align: center;
	color: #000;
	background-color: #fff;
	background-image: unset;
	height: 42px;
	width: 42px;
	align-content: center;
}

#page-indicators > div.active {
	color: #fff;
	background-image: linear-gradient(280deg, #a100dd, #46289b);
	cursor: pointer;
}

.login {
	width: fit-content;
	margin: auto;
}

.registration-input-container {
	display: block;
	height: 100%;
	margin: 20px;
	border-radius: 12px;
}

#router-view {
	/* top: 125px; */
	height: calc(100vh - 60px);
}

.pad-bottom {
	padding-bottom: 30px;
}

.lines	{
 	position: relative;
 	max-width: 350px;
 	margin: 40px auto;
}

.lines:before {
	content:"";
  	height: 1px;
  	width: 130px;
  	background: white;
  	position: absolute;
  	top: 50%;
  	left: 0;	
}

.lines:after {
	content:"";
  	height: 1px;
 	width: 130px;
  	background: white;
  	position: absolute;
  	top: 50%;
  	right: 0;
}

@media (min-width: 768px) {
	.registration-input-container {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}

	#page-indicators > div {
		height: 60px;
		width: 60px;
	}
}
</style>
